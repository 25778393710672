import React, { useMemo } from 'react';
import CurrentUser from '../common/hooks/authenticationStatus';
import { CurrentHotelProvider } from '../common/hooks/currentHotel';
import { ChakraProvider } from '@chakra-ui/react';
import appTheme from '../common/appTheme';
import RightPageProvider from '../common/hooks/rightPage';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import { useRouter } from 'next/router';
import {useIsHotelIndependentPage} from "../common/hooks/isHotelIndependentPage";

function App({ Component, pageProps, err }) {
  const router = useRouter();

  const isUnloggedPage =
    router.pathname === '/mot-de-passe-oublie' ||
    router.pathname === '/reinitialiser-mot-de-passe';

  const isIndependentFromHotel = useIsHotelIndependentPage();

  return (
    <ChakraProvider resetCSS theme={appTheme}>
      <CurrentUser isUnloggedPage={isUnloggedPage}>
        <RightPageProvider>
          {isIndependentFromHotel &&
                <Component {...pageProps} err={err} />
          }
          {!isIndependentFromHotel &&
            <CurrentHotelProvider isUnloggedPage={isUnloggedPage}>
                <Component {...pageProps} err={err} />
            </CurrentHotelProvider>
          }
        </RightPageProvider>
      </CurrentUser>
    </ChakraProvider>
  );
}

export default App;
